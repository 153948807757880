import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { MdModeEditOutline } from 'react-icons/md'
import { connect } from 'react-redux'
import TemplateMenu from '../../template/menu.template'
import { updateMessageUser, updateUser } from '../../Redux/userDuck'
import { createOrUpdateBusiness } from '../../Redux/businessDuck'

const ProfilePage = ({ data_user, fetching_me, updateMessageUser, fetching_message, updateUser, fetching, createOrUpdateBusiness, fetching_create }) => {
    const [formUser, setFormUser] = useState({ name: '', email: '', phone: '' })
    const [formBusiness, setFormBusiness] = useState({
        document: '', business_name: '', logo: null, address: '', resolution: '', email: '',
        web: '', phone: '', gift: 0, slogan: ''
    })
    const [message, setMessage] = useState({})
    const [image, setImage] = useState(null)

    useEffect(() => {
        if (fetching_message === 2) {
            alert("Editado")
        }
    }, [fetching_message])

    useEffect(() => {
        if (fetching_create === 2) {
            alert("Editado")
        }
    }, [fetching_create])

    useEffect(() => {
        if (fetching === 2) {
            alert("Editado")
        }
    }, [fetching])

    useEffect(() => {
        if (fetching_me === 2) {
            let { business } = data_user
            setFormUser(data_user)
            setFormBusiness(business)

            if (business.logo !== null) {
                setImage(business.logo)
            }
            setMessage(data_user.message)
        }

    }, [fetching_me])

    const updateFieldBusiness = (e) => {
        setFormBusiness({
            ...formBusiness,
            [e.target.name]: e.target.value
        })

    }

    const updateField = (e) => {
        setFormUser({
            ...formUser,
            [e.target.name]: e.target.value
        })
    }

    const onSave = () => {
        updateUser(formUser)
    }
    const onSaveBusiness = () => {

        createOrUpdateBusiness(formBusiness)
    }

    const onEditMessage = () => {
        updateMessageUser(message)
    }

    const updateMessage = (e) => {
        setMessage({
            ...message,
            [e.target.name]: e.target.value
        })
    }

    const inputFile = (e) => {
        setFormBusiness({ ...formBusiness, logo: e.target.files[0] });

        let file = e.target.files[0];
        let reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setImage(reader.result);
            }
        };
        reader.readAsDataURL(file);
    };

    return (
        <TemplateMenu>
            <Row className='mt-3'>
                <Col><h3>Perfil</h3></Col>

            </Row>
            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col>Mi Usuario</Col>
                                <Col className='d-flex justify-content-end'>
                                    <button className='btn btn-sm btn-info' onClick={onSave}>Editar</button>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>Nombre</Col>
                                <Col>
                                    <input className='form-control' name='name' value={formUser.name} onChange={updateField} />
                                </Col>
                            </Row>

                            <Row className='my-2'>
                                <Col>Telefono</Col>
                                <Col>
                                    {formUser.phone}
                                    {/* <input className='form-control' name='phone' value={formUser.phone} onChange={updateField} /> */}
                                </Col>
                            </Row>
                            <Row className=''>
                                <Col>Email</Col>
                                <Col>{formUser.email}</Col>
                            </Row>
                        </Card.Body>
                    </Card>

                </Col>
                <Col>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col>Mi Empresa</Col>
                                <Col className='d-flex justify-content-end'>
                                    <button className='btn btn-sm btn-info' onClick={onSaveBusiness}>Editar</button>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>Razón Social</Col>
                                <Col>
                                    <input className='form-control' name='business_name' value={formBusiness.business_name} onChange={updateFieldBusiness} />
                                </Col>
                            </Row>
                            <Row className='my-2'>
                                <Col>Nit ó Documento</Col>
                                <Col>
                                    <input className='form-control' name='document' value={formBusiness.document} onChange={updateFieldBusiness} />
                                </Col>
                            </Row>
                            <Row className='my-1'>
                                <Col>Teléfono</Col>
                                <Col>
                                    <input className='form-control' name='phone' value={formBusiness.phone} onChange={updateFieldBusiness} />
                                </Col>
                            </Row>
                            <Row className='my-1'>
                                <Col>Email</Col>
                                <Col>
                                    <input className='form-control' name='email' value={formBusiness.email} onChange={updateFieldBusiness} />
                                </Col>
                            </Row>
                            <Row className='my-1'>
                                <Col>Dirección</Col>
                                <Col>
                                    <textarea rows={4} className='form-control' name='address' value={formBusiness.address} onChange={updateFieldBusiness} />
                                </Col>
                            </Row>
                            <Row className='my-1'>
                                <Col>Eslogan</Col>
                                <Col>
                                    <textarea rows={4} className='form-control' name='slogan' value={formBusiness.slogan} onChange={updateFieldBusiness} />
                                </Col>
                            </Row>
                            <Row className='my-1'>
                                <Col>Resolución</Col>
                                <Col>
                                    <input className='form-control' name='resolution' value={formBusiness.resolution} onChange={updateFieldBusiness} />
                                </Col>
                            </Row>
                            <Row className='my-1'>
                                <Col>Cantidad para aplicar en Obsequio</Col>
                                <Col>
                                    <input type='number' className='form-control' name='gift' value={formBusiness.gift} onChange={updateFieldBusiness} />
                                </Col>
                            </Row>
                            <Row className='my-1'>
                                <Col>Pagina Web</Col>
                                <Col>
                                    <input className='form-control' name='web' value={formBusiness.web} onChange={updateFieldBusiness} />
                                </Col>
                            </Row>
                            <Row className='my-2'>
                                <Col>Logo</Col>
                                <Col >
                                    {image !== null &&
                                        <Row className='d-flex justify-content-center mb-2'>
                                            <Col lg={6}><img src={image} className="img-fluid" /></Col>
                                        </Row>}

                                    <Row className=''>
                                        <Col><input className='form-control' name='logo' type={"file"} onChange={inputFile} /></Col>
                                    </Row>

                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col lg={6}>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col>Mensaje WhatsApp</Col>
                                <Col className='d-flex justify-content-end'>
                                    <button className='btn btn-sm btn-info' onClick={onEditMessage}>Editar</button>
                                </Col>
                            </Row>

                        </Card.Header>
                        <Card.Body>
                            <Row className='my-3'>
                                <Col>
                                    <h3>Mensaje de Creación</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <textarea className='form-control' value={message.body} name="body" rows={15} onChange={updateMessage}></textarea>
                                </Col>
                            </Row>
                            <Row className='my-3 pt-3'>
                                <Col>
                                    <h3>Mensaje de Actualización</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <textarea className='form-control' value={message.update_message} name="update_message" rows={10} onChange={updateMessage}></textarea>
                                </Col>
                            </Row>
                            <Row className='my-3 pt-3'>
                                <Col>
                                    <h3>Mensaje de Finalización</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <textarea className='form-control' value={message.finish_message} name="finish_message" rows={10} onChange={updateMessage}></textarea>
                                </Col>
                            </Row>
                            <Row>
                                <Col>

                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { user, business } = props


    return {
        data_user: user.data,
        fetching_me: user.fetching_me,
        fetching_message: user.fetching_message,
        fetching: user.fetching_update,
        fetching_create: business.fetching_create
    }
}

export default connect(mapsState, { updateMessageUser, updateUser, createOrUpdateBusiness })(ProfilePage);