import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import userReducer, { restoreSessionAction } from './userDuck'
import productReducer from './productDuck'
import alertReducer from './alertDuck'
import categoryReducer from './categoryDuck'
import parameterReducer from './parameterDuck'
import menuReducer from './menuDuck'
import reportReducer from './reportDuck'
import clientReducer from './clientDuck'
import businessReducer from './businessDuck'
import branchOfficeReducer from './branch.officeDuck'
import rolReducer from './rolDuck'
import permissionReducer from './permissionDuck'
import orderReducer from './orderDuck'
import serviceReducer from './serviceDuck'
import expenseReducer from './expenseDuck'
import supplierReducer from './supplierDuck'
import inventoryReducer from './inventoryDuck'
import purchaseReducer from './purchaseDuck'
import publicityReducer from './publicityDuck'
import parkingReducer from './parkingDuck'
import turnReducer from './turnDuck'
import detailinReducer from './detailingDuck'
import cotizationReducer from './cotizationDuck'
import brandReducer from './brandDuck'
import brandModelReducer from './brandModelDuck'
import vehicleReducer from './vehicleDuck'
import familyReducer from './familyDuck'
import productInventoryReducer from './productInventoryDuck'
import categoryProductReducer from './categoryProductDuck'
import prescriptionReducer from './prescriptionDuck'
import whatsappReducer from './whatsappDuck'
import AdminReducer from './adminDuck'
import DepartmentCityReducer from './departmentCityDuck'
import briefcaseReducer from './briefcaseDuck'
import payrollReducer from './payrollDuck'
import otherRevenuesReducer from './otherRevenuesDuck'


let rootReducer = combineReducers({
    user: userReducer,
    product: productReducer,
    alert: alertReducer,
    category: categoryReducer,
    parameter: parameterReducer,
    menu: menuReducer,
    report: reportReducer,
    client: clientReducer,
    business: businessReducer,
    branch_office: branchOfficeReducer,
    rol: rolReducer,
    permission: permissionReducer,
    order: orderReducer,
    service: serviceReducer,
    expense: expenseReducer,
    supplier: supplierReducer,
    inventory: inventoryReducer,
    purchase: purchaseReducer,
    publicity: publicityReducer,
    parking: parkingReducer,
    turn: turnReducer,
    detailing: detailinReducer,
    cotization: cotizationReducer,
    brand: brandReducer,
    brandModel: brandModelReducer,
    vehicle: vehicleReducer,
    family: familyReducer,
    product_inventory: productInventoryReducer,
    category_product: categoryProductReducer,
    prescription: prescriptionReducer,
    whatsapp: whatsappReducer,
    admin: AdminReducer,
    department_city: DepartmentCityReducer,
    briefcase: briefcaseReducer,
    payroll: payrollReducer,
    other_revenues: otherRevenuesReducer,

})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore() {
    let store = createStore(rootReducer,
        composeEnhancers(applyMiddleware(thunk))
    )

    restoreSessionAction()(store.dispatch)

    // if(store.getState().user.loggedIn==true){
    //     getCart()(store.dispatch, store.getState)
    //     getCurrentUser()(store.dispatch, store.getState)
    //     getCategories()(store.dispatch,store.getState)
    // }

    return store
}

