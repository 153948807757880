import React, { useEffect, Fragment, useState } from 'react'
import { connect } from 'react-redux';
import { Row, Col, Table } from 'react-bootstrap';
import { getAllUser } from '../../Redux/userDuck'
import { getRols } from '../../Redux/rolDuck'
import ModalUsers from './ModalUsers'

import RowTableUser from './row.table.user';
import TemplateMenu from '../../template/menu.template'


const Users = ({ getAllUser, data, fetching_list, getRols, fetching_create }) => {

    const [showModal, setShowModal] = useState(false);
    const [list, setList] = useState([]);
    const [formEdit, setFormEdit] = useState({});
    const [status, setStatus] = useState(true);

    useEffect(() => {
        getAllUser(status)
        getRols()
    }, [])

    useEffect(() => {
        if (fetching_list === 2) {
            setList(data)
        }

    }, [fetching_list])

    useEffect(() => {
        if (fetching_create === 2) {
            setShowModal(false)
            getAllUser(status)
        }

    }, [fetching_create])


    const editUser = (item) => {
        setFormEdit(item)
        setShowModal(true)
    }
    const onHide = () => {
        setShowModal(false);
    }

    const showModalTmp = () => {
        setShowModal(true);
    }

    const onSuccess = () => {
        getAllUser(status);
    }

    const filterContent = (txt) => {
        let _list = list

        _list = list.filter(row => row.name.includes(txt) || row.email.includes(txt))

        setList(_list)

    }
    const onCleanFilter = () => {
        setList(data)
    }
    const updateStatus = (e) => {
        setStatus(e.target.checked)
        getAllUser(e.target.checked)

    }

    return (
        <TemplateMenu>
            <Row className="mt-4">
                <Col lg={10} className="pl-2">
                    <button className='btn btn-success btn-sm' onClick={showModalTmp}>Crear usuario</button>
                </Col>
                <Col lg={2} className="pr-4 justify-content-end d-flex align-items-center">
                    <input className='form-control' type="checkbox" checked={status} onChange={updateStatus} />

                </Col>
            </Row>

            <Row className="mt-3">
                <Col>
                    <Table responsive className="table table-sm table-bordered table-hover">
                        <thead className='thead-dark'>
                            <tr>
                                <th>Fecha de Creación</th>
                                <th>Nombres</th>
                                <th>Correo Electrónico</th>
                                <th>No. Celular</th>
                                <th>Documento</th>
                                <th>Empresa</th>
                                <th>Sucursal</th>
                                <th>Rol Usuario</th>
                                <th>Porcentaje</th>
                                <th>Tipo</th>
                                <th>Estado</th>
                                <th>Modificar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(list.length > 0) ? list.map(row => <RowTableUser key={row.id} item={row} onEdit={editUser} />) : <tr><td colSpan={10}>No tienes Usuarios</td></tr>}
                        </tbody>
                    </Table>
                    {/* <PaginationTable /> */}
                </Col>

            </Row>

            <ModalUsers showModal={showModal} onHide={() => onHide()} onSuccess={() => onSuccess()} formEdit={formEdit} />

        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { user } = props

    return {
        data: user.list,
        fetching_list: user.fetching_list,
        fetching_create: user.fetching_create,
    }
}

export default connect(mapsState, { getAllUser, getRols })(Users);