import React, { useState, useEffect, Fragment, useCallback, useRef } from 'react';
import RowTableClient from './row.table.client';
import { connect } from 'react-redux';

import { getAllClients, onCreateClient } from '../../Redux/clientDuck'

import { toogleAlert } from '../../Redux/alertDuck'
import { Row, Col, Container } from 'react-bootstrap';
import TemplateMenu from '../../template/menu.template'
import ModalClient from './modal.client';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { RiFileExcel2Fill } from 'react-icons/ri';

const ClientPage = ({ getAllClients, data, fetching, toogleAlert, current_page, last_page, fetching_search, onCreateClient, fetching_create }) => {

    const [list, setList] = useState([])
    const [filter, setFilter] = useState([])
    const observer = useRef()
    const [status, setStatus] = useState(0)
    const [modal, setModal] = useState(false)
    const [form, setForm] = useState({ name: '', phone: '' })
    const tableRef = useRef(null);


    useEffect(() => {

        getAllClients()
        toogleAlert(true)
    }, [])


    useEffect(() => {
        if (fetching_create === 2) {
            setModal(false)
            getAllClients()

        }
    }, [fetching_create])

    useEffect(() => {
        if (fetching === 2) {
            toogleAlert(false)
            setList(data)
            setFilter(data)
        }
    }, [fetching])


    const showModalTmp = () => {
        setModal(true)
    }

    const filterContent = (e) => {
        console.log('',);

        let txt = e.target.value
        let _list = list.filter(d => d.name.toLowerCase().includes(txt.toLowerCase()));
        setFilter(_list)
    }

    const onCleanFilter = () => {
        getAllClients()
    }

    const lastBookElementRef = useCallback(node => {
        if (observer.current) observer.current.disconnect()

        // console.log('current_page',current_page)

        observer.current = new IntersectionObserver(entries => {

            if (entries[0].isIntersecting && current_page <= last_page) {
                toogleAlert(true, "Cargando datos...")
                getAllClients(parseInt(current_page) + 1)

            }
        })

        if (node) observer.current.observe(node)

    }, [current_page, status])

    const onEdit = (row) => {
        setModal(true)
        setForm(row)
    }

    const onSuccess = () => {
        setModal(false)
        getAllClients()
    }

    const onCreateClientModal = (form) => {
        onCreateClient(form)
    }

    return (
        <TemplateMenu>
            <Row className="mb-3 mt-4">
                <Col lg={10} className="pl-2">
                    <button className='btn btn-sm btn-success' onClick={showModalTmp}>Crear Cliente</button>
                </Col>
                <Col lg={2} className="pr-4 justify-content-end">
                    <input className='form-control' onChange={filterContent} placeholder="Buscar Cliente" />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <DownloadTableExcel
                                filename="clientes"
                                sheet="clientes"
                                currentTableRef={tableRef.current}>
                                <RiFileExcel2Fill color='green' size={30} className='cursor-pointer' />
                            </DownloadTableExcel>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row>
                <Col>
                    <table className="table table-sm table-hover table-bordered" ref={tableRef}>
                        <thead className='thead-dark'>
                            <tr>
                                <th></th>
                                <th>Nombre Cliente</th>
                                <th>Correo Electrónico</th>
                                <th>No. Celular</th>
                                <th>Documento</th>
                                <th>Modificar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(filter.length > 0) ? filter.map((row, i) => <RowTableClient
                                onEdit={onEdit}
                                item={row}
                                index={i}
                                key={i}
                                refs={lastBookElementRef} />) : <tr><td colSpan={5} align="center">No Tiene Clientes Registrados</td></tr>}
                        </tbody>


                    </table>
                </Col>
            </Row>

            <ModalClient show={modal} onHide={() => setModal(false)} onCreateClient={onCreateClientModal} />

        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { client } = props
    let { clients, fetching_client, current_page, last_page, fetching_search } = props.client

    return {
        fetching_create: client.fetching_create,
        data: clients,
        fetching: fetching_client,
        current_page,
        last_page,
        fetching_search
    }
}

export default connect(mapsState, { getAllClients, toogleAlert, onCreateClient })(ClientPage);