import axios from 'axios';
import { getHeaders } from '../utils'
import moment from 'moment';

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching_list: 0,
    fetching_by_id: 0,
    fetching_worker: 0,
    fetching_liquidate: 0,
    fetching_update: 0,
    fetching_pdf: 0,
    fetching_sync: 0,
    fetching_percent: 0,
    fetching_payment: 0,
    fetching_document: 0,
    fetching_create: 0,
    fetching_score: 0,
    fetching_rating: 0,
    fetching_briefcase: 0,
    fetching_briefcase_account: 0,
    data: [],
    data_rating: [],
    data_briefcase: [],
    data_briefcase_account: [],
    documents: [],
    data_worker: [],
    range: {},
    data_pdf: {},
    row: {},
    errors: {}
}

const LIST_ORDER = "LIST_ORDER"
const LIST_ORDER_SUCCESS = "LIST_ORDER_SUCCESS"
const ORDER_BY_ID = "ORDER_BY_ID"
const ORDER_BY_ID_SUCCESS = "ORDER_BY_ID_SUCCESS"
const ORDER_BY_WORKER = "ORDER_BY_WORKER"
const ORDER_BY_WORKER_SUCCESS = "ORDER_BY_WORKER_SUCCESS"
const LIQUIDATE = "LIQUIDATE"
const LIQUIDATE_SUCCESS = "LIQUIDATE_SUCCESS"
const LIQUIDATE_PDF = "LIQUIDATE_PDF"
const LIQUIDATE_PDF_SUCCESS = "LIQUIDATE_PDF_SUCCESS"
const ON_EDIT_PERCENT = "ON_EDIT_PERCENT"
const ON_EDIT_PERCENT_SUCCESS = "ON_EDIT_PERCENT_SUCCESS"
const ADD_COST_WORKER = "ADD_COST_WORKER"
const ADD_COST_WORKER_SUCCESS = "ADD_COST_WORKER_SUCCESS"
const SYNC_SALES = "SYNC_SALES"
const SYNC_SALES_SUCCESS = "SYNC_SALES_SUCCESS"
const PERCENT = "PERCENT"
const PERCENT_SUCCESS = "PERCENT_SUCCESS"
const PAYMENT = "PAYMENT"
const PAYMENT_SUCCESS = "PAYMENT_SUCCESS"
const UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT"
const UPLOAD_DOCUMENT_SUCCESS = "UPLOAD_DOCUMENT_SUCCESS"
const CREATE_ORDER = "CREATE_ORDER"
const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS"
const ORDER_SCORE = "ORDER_SCORE"
const ORDER_SCORE_SUCCESS = "ORDER_SCORE_SUCCESS"
const RATING_ORDER = "RATING_ORDER"
const RATING_ORDER_SUCCESS = "RATING_ORDER_SUCCESS"
const LIST_RATING = "LIST_RATING"
const LIST_RATING_SUCCESS = "LIST_RATING_SUCCESS"
const LIST_BRIEF = "LIST_BRIEF"
const LIST_BRIEF_SUCCESS = "LIST_BRIEF_SUCCESS"
const LIST_BRIEF_ACCOUNT = "LIST_BRIEF_ACCOUNT"
const LIST_BRIEF_ACCOUNT_SUCCESS = "LIST_BRIEF_ACCOUNT_SUCCESS"


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_ORDER:
            return { ...state, fetching_list: 1, fetching_create: 0 }
        case LIST_ORDER_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        case ORDER_BY_ID:
            return { ...state, fetching_by_id: 1 }
        case ORDER_BY_ID_SUCCESS:
            return { ...state, fetching_by_id: 2, row: action.payload }
        case ORDER_BY_WORKER:
            return { ...state, fetching_worker: 1, data_worker: [], range: [] }
        case ORDER_BY_WORKER_SUCCESS:
            return { ...state, fetching_worker: 2, data_worker: action.payload, range: action.range }
        case LIQUIDATE:
            return { ...state, fetching_liquidate: 1 }
        case LIQUIDATE_SUCCESS:
            return { ...state, fetching_liquidate: 2 }
        case LIQUIDATE_PDF:
            return { ...state, fetching_pdf: 1 }
        case LIQUIDATE_PDF_SUCCESS:
            return { ...state, fetching_pdf: 2, data_pdf: action.payload }
        case ON_EDIT_PERCENT:
            return { ...state, fetching_update: 1 }
        case ON_EDIT_PERCENT_SUCCESS:
            return { ...state, fetching_update: 2, }
        case ADD_COST_WORKER:
            return { ...state, fetching_update: 1 }
        case ADD_COST_WORKER_SUCCESS:
            return { ...state, fetching_update: 2 }
        case SYNC_SALES:
            return { ...state, fetching_sync: 1 }
        case SYNC_SALES_SUCCESS:
            return { ...state, fetching_sync: 2 }
        case PERCENT:
            return { ...state, fetching_percent: 1 }
        case PERCENT_SUCCESS:
            return { ...state, fetching_percent: 2 }
        case PAYMENT:
            return { ...state, fetching_payment: 1 }
        case PAYMENT_SUCCESS:
            return { ...state, fetching_payment: 2 }
        case UPLOAD_DOCUMENT:
            return { ...state, fetching_document: 1 }
        case UPLOAD_DOCUMENT_SUCCESS:
            return { ...state, fetching_document: 2, documents: action.payload }
        case CREATE_ORDER:
            return { ...state, fetching_create: 1 }
        case CREATE_ORDER_SUCCESS:
            return { ...state, fetching_create: 2 }
        case ORDER_SCORE:
            return { ...state, fetching_score: 1 }
        case ORDER_SCORE_SUCCESS:
            return { ...state, fetching_score: 2 }
        case RATING_ORDER:
            return { ...state, fetching_rating: 1 }
        case RATING_ORDER_SUCCESS:
            return { ...state, fetching_rating: 2 }
        case LIST_RATING:
            return { ...state, fetching_rating: 1 }
        case LIST_RATING_SUCCESS:
            return { ...state, fetching_rating: 2, data_rating: action.payload }
        case LIST_BRIEF:
            return { ...state, fetching_briefcase: 1 }
        case LIST_BRIEF_SUCCESS:
            return { ...state, fetching_briefcase: 2, data_briefcase: action.payload }
        case LIST_BRIEF_ACCOUNT:
            return { ...state, fetching_briefcase_account: 1 }
        case LIST_BRIEF_ACCOUNT_SUCCESS:
            return { ...state, fetching_briefcase_account: 2, data_briefcase_account: action.payload }
        default:
            return state;
    }
}

export let getListOrders = (form) => (dispatch, getState) => {
    dispatch({
        type: LIST_ORDER
    })

    if (getState().user.loggedIn) {

        let url = "";

        if (form !== undefined) {
            let { plate, date_init, date_end, payment_id, operator_id, category_id, administrator_id, turn_id } = form

            url = `${API_URL}/api/orders?date_init=${date_init}&date_end=${date_end}&payment_id=${payment_id}&operator_id=${operator_id}&category_id=${category_id}&status_id=${form.status_id}&administrator_id=${administrator_id}&plate=${plate}&turn_id=${turn_id}`
        } else {
            let { date_init, date_end } = JSON.parse(localStorage.date);
            url = `${API_URL}/api/orders?date_init=${date_init}&date_end=${date_end}`
        }

        let branch = JSON.parse(localStorage.getItem("branch"))


        url += `&branch_id=${branch.id}`;


        fetch(url, {
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            dispatch({
                type: LIST_ORDER_SUCCESS,
                payload: resp.results
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let getListOrderWorker = (range) => (dispatch, getState) => {
    dispatch({
        type: ORDER_BY_WORKER
    })

    if (getState().user.loggedIn) {

        console.log('range', range);

        fetch(`${API_URL}/api/orders-worker?date_init=${range.date_init}&date_end=${range.date_end}&status_id=${range.status_id}&cashier_id=${range.turn_id}`, {
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            dispatch({
                type: ORDER_BY_WORKER_SUCCESS,
                payload: resp.results,
                range: resp.range
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }

}

export let getListOrdersActive = () => (dispatch, getState) => {
    dispatch({
        type: LIST_ORDER
    })

    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.branch)
        let date = JSON.parse(localStorage.date)


        fetch(`${API_URL}/api/orders-list/1?status=1`, {
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            dispatch({
                type: LIST_ORDER_SUCCESS,
                payload: resp.results
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }

}

export let getListOrderById = (id) => (dispatch, getState) => {
    dispatch({
        type: ORDER_BY_ID
    })

    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.branch)
        let date = JSON.parse(localStorage.date)

        fetch(`${API_URL}/api/orders/${id}`, {
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            dispatch({
                type: ORDER_BY_ID_SUCCESS,
                payload: resp
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let getOrderByIdDetail = (id) => (dispatch, getState) => {
    dispatch({
        type: ORDER_BY_ID
    })


    fetch(`${API_URL}/api/orders-for-client/${id}`, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: ORDER_BY_ID_SUCCESS,
            payload: resp
        });

    }).catch(function (error) {
        console.log(error);
    });

}

export let getLiquidatePDF = (id, date_init, date_end, cashier_id) => (dispatch, getState) => {
    dispatch({
        type: LIQUIDATE_PDF
    })

    if (getState().user.loggedIn) {


        fetch(`${API_URL}/api/orders-liquidate/${id}/${date_init}/${date_end}/${cashier_id}`, {
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            dispatch({
                type: LIQUIDATE_PDF_SUCCESS,
                payload: resp
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let onLiquidate = (data) => (dispatch, getState) => {
    dispatch({
        type: LIQUIDATE
    })

    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.getItem("branch"))

        data.branch_id = branch.id

        fetch(`${API_URL}/api/orders-liquidate`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            dispatch({
                type: LIQUIDATE_SUCCESS,
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }

}

export let onUpdatePercentOrders = (data) => (dispatch, getState) => {
    dispatch({
        type: PERCENT
    })

    if (getState().user.loggedIn) {

        fetch(`${API_URL}/api/orders-change-percent`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            dispatch({
                type: PERCENT_SUCCESS,
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }

}

export let onEditPercent = (item) => (dispatch, getState) => {
    dispatch({
        type: ON_EDIT_PERCENT
    })

    if (getState().user.loggedIn) {
        fetch(`${API_URL}/api/orders-detail/${item.id}`, {
            method: "PUT",
            body: JSON.stringify(item),
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            dispatch({
                type: ON_EDIT_PERCENT_SUCCESS,
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let onCreateOrder = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_ORDER
    })

    let branch = JSON.parse(localStorage.getItem("branch"))


    form.operators = JSON.stringify(form.operators)
    form.services = JSON.stringify(form.services)
    form.products = JSON.stringify(form.products)
    form.notification = JSON.stringify(form.notification)
    form.branch_id = branch.id
    form.registration_date = moment().format("YYYY-MM-DD")

    if (getState().user.loggedIn) {
        fetch(`${API_URL}/api/orders`, {
            method: "POST",
            body: JSON.stringify(form),
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            dispatch({
                type: CREATE_ORDER_SUCCESS,
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }


}
export let onChangePayment = (form) => (dispatch, getState) => {
    dispatch({
        type: PAYMENT
    })

    if (getState().user.loggedIn) {
        fetch(`${API_URL}/api/orders-change-payment/${form.order_id}`, {
            method: "PUT",
            body: JSON.stringify(form),
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            dispatch({
                type: PAYMENT_SUCCESS,
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }

}

export let syncSales = () => (dispatch, getState) => {
    dispatch({
        type: SYNC_SALES
    })

    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.getItem("branch"))

        let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))

        fetch(`${API_URL}/api/orders-sync-sales?date_init=${date_init}&date_end=${date_end}&branch=${branch.id}`, {
            method: "POST",
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            dispatch({
                type: SYNC_SALES_SUCCESS,
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }

}

export let onAddCostWorker = (form) => (dispatch, getState) => {
    dispatch({
        type: ADD_COST_WORKER
    })

    if (getState().user.loggedIn) {
        fetch(`${API_URL}/api/cost/worker`, {
            method: "POST",
            body: JSON.stringify(form),
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            dispatch({
                type: ADD_COST_WORKER_SUCCESS,
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }

}

export let uploadDocument = (file) => (dispatch, getState) => {
    dispatch({
        type: UPLOAD_DOCUMENT
    })

    if (getState().user.loggedIn) {
        let _form = new FormData()
        _form.append("file", file);

        axios.post(`${API_URL}/api/orders-document`, _form, {
            headers: getHeaders()

        }).then(resp => {

            dispatch({
                type: UPLOAD_DOCUMENT_SUCCESS,
                payload: resp.data,
            });

        }).catch(error => {

        });
    } else {
        console.log("no Ingreso");
    }

}

export let onScoreOrder = (id, rating, description) => (dispatch, getState) => {
    dispatch({
        type: RATING_ORDER
    })

    fetch(`${API_URL}/api/orders-for-client/${id}`, {
        method: "PUT",
        body: JSON.stringify({ rating, observation_score: description }),
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: RATING_ORDER_SUCCESS,
        });

    }).catch(function (error) {
        console.log(error);
    });

}

export let getListRating = (form) => (dispatch, getState) => {
    dispatch({
        type: LIST_RATING
    })

    if (getState().user.loggedIn) {

        let date = JSON.parse(localStorage.date)

        let branch = JSON.parse(localStorage.getItem("branch"))

        console.log('date::', date);


        fetch(`${API_URL}/api/orders-rating?cashier_id=${form.turn_id}&date_init=${form.date_init}&date_end=${form.date_end}&branch_id=${branch.id}`, {
            method: "get",
            headers: getHeaders()
        })
            .then(resp => resp.json())
            .then(resp => {
                dispatch({
                    type: LIST_RATING_SUCCESS,
                    payload: resp.results
                });

            }).catch(function (error) {
                console.log(error);
            });
    } else {
        console.log("no Ingreso");
    }
}

